import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileInvoiceDollar, faDollar, faTrash, faPrint } from '@fortawesome/free-solid-svg-icons';
import { alert } from 'devextreme/ui/dialog';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { formatStringToCurrency, getUrl } from '../../../../shared/utils/Utils';
import { selectNovaOs, useNovaOs } from './../../../../redux/slices/consignadoComodato/novaOsSlice';
import { ApiAlterarPagamentoService } from './../usercases/apiAlterarPagamento.service';
import { ApiGerarBoletoService } from './../usercases/apiGerarBoleto.service';
import { ApiRemoverPagamentoService } from './../usercases/apiRemoverPagamento.service';
import styles from './pagamento.module.scss';
import moment from 'moment';
import { selectConsignadoComodatoSharedData, useConsignadoComodatoSharedData } from '../../../../redux/slices/consignadoComodato/SharedData.slice';
import { ApiRecuperarOsService } from '../../consignado-comodato-lista/usercases/ApiRecuperarOs.service';
import { ComodatoService } from '../../../../services/con-comodato/Comodato.service';
import Swal from 'sweetalert2';
import { TurnoPedidoService } from '../../../../services/turno-pedido/TurnoPedido.service';

export function PagamentosLista({ pedido, idx, setSearching, setMessageLoadin }) {
  const { receberPagamento, removerPagamento, atualizarPagamento, changeValue, changeValuePedidos } = useNovaOs();
  const { updateState } = useConsignadoComodatoSharedData();
  const dispatch = useDispatch();
  const { values } = useSelector(selectNovaOs);
  const parametro522 = useSelector(selectConsignadoComodatoSharedData).parametro522;

  const onReceber = useCallback(
    async arg => {
      const result = await Swal.fire({
        title: 'Atenção!',
        text: 'Confirma o recebimento deste pagamento no pedido?',
        icon: 'info',
        timer: 5000,
        confirmButtonText: 'Sim, continuar!',
        cancelButtonText: 'Não, cancelar!',
        showCancelButton: true,
      });

      if (result.dismiss === Swal.DismissReason.cancel) {
        return;
      }

      const payment = { ...arg.payment, vencimento: 'RECEBIDO' };
      if (values.id !== -1) {
        setMessageLoadin('Recebendo pagamento...');
        setSearching(true);
        const data = {
          pagamentoId: payment.pagamento.id,
          valorPagamento: payment.valorPagamento,
          osId: values.osId,
          pedidoId: pedido.pedidoId,
          uid: payment.uid,
        };

        try {
          await new ApiAlterarPagamentoService().execute(data);
          dispatch(receberPagamento({ payment, idx: arg.idx, pedidoId: pedido.pedidoId }));
        } catch (error) {
          if (error.message.includes('[OS687]')) {
            const ret = await Swal.fire({
              icon: 'info',
              title: 'Atenção!',
              text: 'Não existe um turno aberto para o usuário logado. Deseja abrir um turno?',
              showCancelButton: true,
              confirmButtonText: 'Sim',
              cancelButtonText: 'Não',
            });

            if (ret.isConfirmed) {
              const turno = await TurnoPedidoService.getInstance().abrirTurno();
              dispatch(updateState({ name: 'turnoAtual', data: turno }));
              await onReceber(arg); // Tente receber o pagamento novamente.
            }
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Falha salvando Pedido',
              text: error.message,
            });
          }
        } finally {
          setSearching(false);
        }
      }
    },
    [dispatch, receberPagamento, values, pedido, setSearching, setMessageLoadin, updateState],
  );


  const onRemover = useCallback(
    async arg => {
      const payment = { ...arg };
      //console.log('payment', payment);
      Swal.fire({
        title: 'Tem certeza que deseja remover este pagamento do pedido?',
        text: 'Esta ação não poderá ser desfeita!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, continuar!',
        cancelButtonText: 'Não, cancelar!',
      }).then(async result => {
        if (result.isConfirmed) {
          if (values.id !== -1) {
            try {
              setMessageLoadin('Removendo pagamento...');
              setSearching(true);
              const data = {
                pagamentoId: payment.pagamento.id,
                valorPagamento: payment.valorPagamento,
                osId: values.osId,
                pedidoId: pedido.pedidoId,
                uid: payment.uid,
              };
              await new ApiRemoverPagamentoService().execute(data);
              setSearching(false);
            } catch (ex) {
              setSearching(false);
              alert(ex.message, 'ERRO!');
              return;
            }
          }
          const acrescimoPedido = (pedido || {}).acrescimo || 0;
          const juros = pedido.pagamentos[payment.idx].juros;
          const acrescimo = acrescimoPedido - juros;

          dispatch(
            changeValuePedidos({
              pedido: { ...pedido, acrescimo: acrescimo },
            }),
          );
          dispatch(removerPagamento({ id: payment.id, idx: payment.idx, pedidoId: pedido.pedidoId }));
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          return;
        }
      });
    },
    [dispatch, removerPagamento, values, pedido, setSearching, setMessageLoadin, changeValuePedidos],
  );

  const onGeraBoleto = useCallback(
    async arg => {
      const result = await Swal.fire({
        title: 'Atenção!',
        text: 'Confirma a geração do boleto para este pagamento?',
        icon: 'info',
        timer: 5000,
        showCancelButton: true,
        confirmButtonText: 'Sim, continuar!',
        cancelButtonText: 'Não, cancelar!',
      });

      if (result.dismiss === Swal.DismissReason.cancel) {
        return;
      }

      const payment = { ...arg, vencimento: 'À VISTA' };

      if (values.id !== -1) {
        try {
          // Verifica se o turno está aberto
          const turnoAtual = await TurnoPedidoService.getInstance().turnoAtual();
          if (!turnoAtual) {
            const ret = await Swal.fire({
              icon: 'info',
              title: 'Atenção!',
              text: 'Não existe um turno aberto para o usuário logado. Deseja abrir um turno?',
              showCancelButton: true,
              confirmButtonText: 'Sim',
              cancelButtonText: 'Não',
            });

            if (ret.isConfirmed) {
              const turno = await TurnoPedidoService.getInstance().abrirTurno();
              dispatch(updateState({ name: 'turnoAtual', data: turno }));
            } else {
              return;
            }
          }

          let utilizaOutrasCobrancas = (parametro522 || {}).valor === '1';
          let cobrarOutrasCobrancas = false;

          if (payment.valorOutrasCobrancas !== undefined && payment.valorOutrasCobrancas > 0 && utilizaOutrasCobrancas) {
            cobrarOutrasCobrancas = await Swal.fire({
              icon: 'question',
              title: 'Adicionar Outras Cobranças?',
              text: `Esse boleto possui configurado o valor adicional de R$${payment.valorOutrasCobrancas.toFixed(2)}, deseja adicionar esse valor na sua geração?`,
              showCancelButton: true,
              confirmButtonText: 'Sim',
              cancelButtonText: 'Não',
            }).then(result => result.isConfirmed);
          }

          setMessageLoadin('Gerando boleto...');
          setSearching(true);

          const data = {
            pagamentoId: payment.id,
            FazerCobrancaAdicional: cobrarOutrasCobrancas,
          };

          const retorno_dados = await new ApiGerarBoletoService().execute(data);

          if (retorno_dados.statusText === 'OK') {
            payment.boletoId = retorno_dados.data.boletoId;
            payment.boletoNome = retorno_dados.data.boletoNome;
          }

          alert('Boleto gerado com sucesso');
          setSearching(false);

          await ComodatoService.getInstance().sincronizarTurnoPedidoPagamento(payment.id);
          const os = await new ApiRecuperarOsService().execute(values.id);
          if (os) {
            dispatch(changeValue({ fieldName: 'pedidos', value: os.pedidos }));
            dispatch(changeValue({ fieldName: 'status', value: os.status }));
          }
        } catch (ex) {
          setSearching(false);
          alert(ex.message, 'ERRO!');
        }
      }

      dispatch(atualizarPagamento({ idx, payment }));
    },
    [values.id, dispatch, atualizarPagamento, idx, parametro522, setMessageLoadin, setSearching, updateState, changeValue],
  );

  const onImprimeBoleto = useCallback(
    async arg => {
      const payment = { ...arg, vencimento: 'À VISTA' };

      if (values.id !== -1) {
        try {
          setMessageLoadin('Baixando o boleto...');
          setSearching(true);

          const data = {
            boletoId: payment.boletoId,
            nome: payment.boletoNome,
          };

          var retorno_dados = await new ApiGerarBoletoService().imprimeBoleto(data);

          if (retorno_dados.statusText === 'OK') {
            let url = getUrl();

            var boletoPDF = payment.boletoNome
              .replace('-', '')
              .replace('\\', '')
              .replace('/', '');

            var boletoUrl = `${url}/Boletos/${boletoPDF}.pdf`;
            window.open(boletoUrl, '_blank').focus();
          }

          setSearching(false);
        } catch (ex) {
          setSearching(false);
          alert(ex.message, 'ERRO!');
          return;
        }
      }
    },
    [values, setSearching, setMessageLoadin],
  );

  return (
    <>
      <ul className={`${styles.table} list-group mb-0`}>
        {(pedido.pagamentos || []).map((payment, i) => (
          <li key={i} className="list-group-item m-0 pt-0 pb-1 pr-0">
            <div className="d-flex">
              <div className="d-flex flex-column flex-grow-1">
                <div className="d-flex">
                  <div className="">
                    <label className="text-muted p-0 m-0" style={{ fontSize: '9px', marginBottom: '-5px' }}>
                      Meio de Pagamento
                    </label>
                    <div className={`font-weight-bold text-primary p-0`} style={{ marginTop: '-5px' }}>
                      {((payment.pagamento || {}).descricao || '').toUpperCase()}
                    </div>
                  </div>
                  <div className="ml-3">
                    <label className="text-muted p-0 m-0" style={{ fontSize: '9px', marginBottom: '-5px' }}>
                      Vencimento
                    </label>
                    <div className="ml-auto text-center" style={{ marginTop: '-5px' }}>
                      {payment.dataVencimento ? moment(payment.dataVencimento).format('DD/MM/YYYY') : payment.vencimento}
                    </div>
                  </div>
                </div>

                {payment.info && [-4, -7].includes(parseInt((payment.pagamento || {}).id, 10)) && (
                  <div className="d-flex" style={{ marginTop: '-5px' }}>
                    <div className="">
                      <label className="text-muted p-0 m-0" style={{ fontSize: '9px', marginBottom: '-5px' }}>
                        Dados do Cartão
                      </label>
                      <div className={`p-0`} style={{ marginTop: '-5px' }}>
                        {`${(payment.info.redeNome || '').toUpperCase()} - ${(payment.info.bandNome || '').toUpperCase()} - ${(payment.info.prReNome || '').toUpperCase()}`}
                      </div>
                    </div>
                    {(payment.parcelas && payment.parcelas.length > 0 && payment.parcelas[payment.parcelas.length - 1].numeroParcela > 0) && (
                      <div className="" style={{paddingLeft: '25px' }}>
                        <label className="text-muted p-0 m-0" style={{ fontSize: '9px', marginBottom: '-5px' }}>
                          Parcelado em:
                        </label>
                        <div className={`p-0`} style={{ marginTop: '-5px', paddingLeft: '25px' }}>
                          {(() => {
                            const ultimaParcela = payment.parcelas[payment.parcelas.length - 1];
                            return `${ultimaParcela.numeroParcela}x de: R$ ${ultimaParcela.valorParcela} - juros de ${ultimaParcela.taxa}% am`;
                          })()}
                        </div>
                      </div>
                    )}
                  </div>
                )}
                {payment.turno && (
                  <div className="d-flex">
                    {!payment.turno.dataFechamento && !payment.turno.turnoConferido && <div className={`p-0 font-weight-bold  text-success`}>TURNO ABERTO&nbsp;</div>}
                    {payment.turno.turnoConferido && <div className={`p-0 font-weight-bold  text-primary`}>TURNO CONFERIDO&nbsp;</div>}
                    {payment.turno.dataFechamento && !payment.turno.turnoConferido && <div className={`p-0 font-weight-bold text-warning`}>TURNO FECHADO&nbsp;</div>}
                    <div className={`p-0`}>{`- ${new Date(payment.turno.dataAbertura || new Date()).toLocaleString()}`}</div>
                    <div className={`p-0 text-primary`}>&nbsp;{`${(payment.turno || '').nomeOperador.toString()}`}</div>
                  </div>
                )}
              </div>
              <div>
                <div className="d-flex align-items-end flex-column h-100">
                  <div className="d-flax">
                    {payment.vencimento === 'A RECEBER' && payment.pagamento.id !== -15 && (
                      <button
                        className="btn btn-icon text-primary text-right"
                        title="Marcar pagamento como recebido"
                        onClick={() => onReceber({ payment, idx: i })}
                        style={{ cursor: 'pointer' }}
                      >
                        <FontAwesomeIcon icon={faDollar} style={{ fontSize: '1.5em' }} />
                      </button>
                    )}
                    {payment.id !== -1 && payment.pagamento && payment.pagamento.id === -15 && payment.boletoId === 0 && (
                      <button className="btn btn-icon text-primary" title="Gerar Boleto" onClick={() => onGeraBoleto(payment)} style={{ cursor: 'pointer' }}>
                        <FontAwesomeIcon icon={faFileInvoiceDollar} style={{ fontSize: '1.5em' }} />
                      </button>
                    )}
                    <button
                      className="btn btn-icon text-primary"
                      title="Remover pagamento"
                      disabled={(values.status || {}).id !== 1 && pedido.tipoPedido.id !== 6}
                      onClick={() => onRemover({ ...payment, idx: i })}
                      style={{ cursor: 'pointer' }}
                    >
                      <FontAwesomeIcon icon={faTrash} style={{ fontSize: '1.5em' }} />
                    </button>

                    {payment.id !== -1 && payment.pagamento && payment.pagamento.id === -15 && payment.boletoId !== 0 && (
                      <button className="btn btn-icon text-primary" title="Imprimir Boleto" onClick={() => onImprimeBoleto(payment)} style={{ cursor: 'pointer' }}>
                        <FontAwesomeIcon icon={faPrint} style={{ fontSize: '1.5em' }} />
                      </button>
                    )}
                  </div>
                  <div className="mt-auto">
                    <h5 className="text-right text-primary font-weight-bold pr-2">{formatStringToCurrency(payment.valorPagamento)}</h5>
                  </div>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </>
  );
}
